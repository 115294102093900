import {
  Container,
  FAB,
  Hint,
  Loading,
  NotificationActions
} from '@elotech/components';
import { Modulos } from 'iss-common/enums';
import { Alert } from 'iss-common/utils';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  ConfiguracaoParametrosService,
  SchedulerService,
  TipoImagemService,
  TipoProcessoAtoService,
  TipoProcessoService,
  TipoRepresentanteService,
  withService
} from '../../../service';
import AgendamentosForm from './agendamento/AgendamentoForm';
import ConfiguracaoEmailForm from './configuracao-email/ConfiguracaoEmailForm';
import DadosGeraisForm from './dados-gerais/DadosGeraisForm';
import DesIfForm from './desif/DesIfForm';
import FiscalizacaoForm from './fiscalizacao/FiscalizacaoForm';
import IssForm from './iss/IssForm';
import NfseNacionalForm from './nfse-nacional/NfseNacionalForm';
import NfseForm from './nfse/NfseForm';
import RotinasForm from './rotinas/RotinasForm';
import SolicitacaoRPSForm from './solicitacao-rps/SolicitacaoRPSForm';
import TributosForm from './tributos/TributosForm';
import UnicoTributosWebForm from './unico-tributos-web/UnicoTributosWebForm';

export class ParametrosPage extends Component {
  state = {
    parametros: {
      NOMEPREFEITURA: {},
      NOMESECRETARIAFAZENDA: {},
      NOMESETORTRIBUTARIO: {},
      TIPOIMAGEMLOGOMARCA: {},
      URLAISETRIBUTOSWEB: {},
      DIASCONTRIBUINTEATIVOTEMP: {},
      SITEAUTENTICARNFSE: {},
      DIASAVISOVALIDADECERTIFICADO: {},
      ALIQUOTAMINIMA: {},
      ALIQUOTAMAXIMA: {},
      EXIBEAVISO: {},
      AJUSTEISSCALCULADO: {},
      REGRALOCALINCIDENCIA: {},
      RECEITA_ISS: {},
      SITUACAO_PARCELA_CANCELADA: {},
      HABILTAMSGAVISOENTREGARDMS: {},
      RETERFORADOMUNICIPIO: {},
      ISENCAOGRUPO: {},
      HABILITAEMISSAONFSEDMSFECHADA: {},
      HABILITAISSRETIDO: {},
      CANCELADEBITODMSTOMADO: {},
      USATIPOEMPRESAVENCIMENTODMS: {},
      OBSINCENTIVOALIQUOTASERVICO: {},
      COMPINICIOVERIFICACAODMS: {},
      ISS_AG_DATAFUTURA: {},
      VALIDAUPLOADARQUIVOPRECAD: {},
      DIASLIMITEEMISSAONFSEWEB: {},
      PERCENTUALMAXDEDUCAONFSE: {},
      DIASLIMITEENVIORPS: {},
      HABILITANFSEDATAANTERIOR: {},
      OBRIGATORIODADOSDEDUCAO: {},
      HABILITAINTERMEDIARIO: {},
      LIMITE_SUBST_NFSE: {},
      GERAXMLNFSECONVERTIDA: {},
      FLUXOCANCELAMENTONFSE: {},
      FLUXOSUBSTITUICAONFSE: {},
      USUARIOISSUNICO: {},
      SENHAISSUNICO: {},
      HABILITAIMPORTACAONOTAFISCAL: {},
      HABILITAGUIAAVULSONOTAFISCAL: {},
      HABILITAPCI: {},
      HABILITACONSULTADMSENTREGUE: {},
      HABILITADIGITACAODOCUMENTOS: {},
      HABILITAIMPORTACAODESIF: {},
      HABILITAIMPORTACAORUBRICA: {},
      HABILITACONSULTALANCBANCOS: {},
      HABILITAAUTENTICARDOCFISCAL: {},
      HABILITACONSULTINCENTIVOFISCAL: {},
      HABILITAAGENDARSOLICITACAO: {},
      HABILITAINFORMACOESUSUARIO: {},
      HABILITAINFORMACOESEMPRESA: {},
      HABILITAUSUARIOSECUNDARIO: {},
      HABILITAATUALIZACAOCADASTRAL: {},
      PARAM_SITUACAO_PARCELA_ABERTO: {},
      PARAM_SIT_PARCELA_SEM_MOV: {},
      TIPOREPRESENTANTELEGAL: {},
      TEXTOAPRESENTACAO: {},
      TEXTOALERTA: {},
      NOTAEXPLICATIVACARTACORRECAO: {},
      URLSERVIDORUNICO: {},
      ISONPREMISEUNICO: {},
      URLWEBSERVICE: {},
      URLSERVIDORLICENCA: {},
      CONNECTIONTIMEOUTUNICO: {},
      CONNECTIONTIMEOUTLICENCA: {},
      SOCKETTIMEOUTSERVIDORUNICO: {},
      SOCKETTIMEOUTSERVIDORLICENCA: {},
      HABILITASOLICITACAORPS: {},
      QTD_MAX_POR_SOLICITACAO_RPS: {},
      PERC_LIB_AUTO_SOLICITACAO_RPS: {},
      NFSE_HAB_EDICAO_TOMADOR_PJ: {},
      NFSE_HAB_INSERCAO_TOMADOR_PJ: {},
      OBSERVACAO_FIXA_NFSE: {},
      HABILITA_MOV_DMS_VENCIDO: {},
      URLOXYTRIBUTOS: {},
      USUARIOSMTP: {},
      SERVIDORSMTP: {},
      SMTPPROTOCOL: {},
      PORTASMTP: {},
      SENHASMTP: {},
      SSLSMTP: {},
      REQUERAUTENTICACAO: {},
      DIAS_PARA_REMOVER_SOLICITACAOWS: {},
      HABILITASOLICITACAOPROCESSO: {},
      QTDEDIASCANCELAMENTONFSE: {},
      QTDEDIASSUBSTITUICAONFSE: {},
      TIPO_CONSOLIDACAO_DESIF: {},
      TIPO_ARREDONDAMENTO_DESIF: {},
      PERMITE_IMPOSTO_RETIDO_DESIF: {},
      INFORMA_CONTAS_DESPESAS_DESIF: {},
      EXIGE_DETALHAMENTO_RATEIO_DESIF: {},
      EXIGE_DETALHAMENTO_ESTORNOS_DESIF: {},
      PERMITE_CODIGO_INTERNO_DESIF: {},
      PERMITE_INCENTIVO_0430_DESIF: {},
      PERCENTUAL_MAXIMO_INCENTIVO_0430_DESIF: {},
      PERMITE_INCENTIVO_0440_DESIF: {},
      PERCENTUAL_MAXIMO_INCENTIVO_0440_DESIF: {},
      PERMITE_MAXVALOR_COMPENSAR_0440_DESIF: {},
      MAXVALOR_COMPENSAR_0440_DESIF: {},
      MAXPERCDEVIDO_COMPENSAR_0440_DESIF: {},
      MAXPERCPAGAR_COMPENSAR_0440_DESIF: {},
      PRAZO_MAX_COMPENSAR_0440_DESIF: {},
      VALIDA_DADOS_OBRA: {},
      HABILITASIMPLESMUNICIPAL: {},
      OBRIGA_CNAE_ITEM_LANCAMENTO_SERVICO_TOMADO: {},
      VINCULAR_USUARIO_VARIOS_LOCAIS_INTERNOS: {},
      PERMITE_USUARIO_EDITAR_OUTROS_PROCESSOS_LOCAL_INTERNO: {},
      PERMITE_DEDUCAO_NFSE_TRIBUTADA_FORA_MUNICIPIO: {},
      VALIDA_FATURAMENTO_MEI: {},
      TETO_FATURAMENTO_MEI: {},
      BLOQUEIA_EMISSAO_NFSE_DMS_PENDENTE: {},
      QTD_DIAS_BLOQUEIO_NFSE_DMS_PENDENTE: {},
      HABILITASOLICITACAOBAIXA: {},
      SOLICITACAOBAIXA_TIPO_PROCESSO: {},
      SOLICITACAOBAIXA_TIPO_ATO: {},
      BLOQUEIA_INSERCAO_CNAE_EM_CREDENCIAMENTO: {},
      BLOQUEAR_NFS_E_MEI: {},
      MENSAGEM_BLOQUEAR_NFS_E_MEI: {},
      EXIGE_ANEXO_CANCELAMENTO_NFSE_SERVICO_NAO_PRESTADO: {},
      SIMPLES_NACIONAL_VALOR_SERVICOS_AUTOMATICAMENTE: {},
      RETEM_ISS_COM_BASE_OBRA: {},
      EMAIL_TRIBUTARIO: {},
      TELEFONE_TRIBUTARIO: {},
      ALTERARDATAPRESTACAOSERVICONFSE: {},
      HABILITA_CANCELAMENTO_AUTOMATICO_GUIA: {}
    },

    showFormDadosGerais: true,
    showFormIss: false,
    showFormNfse: false,
    showFormNfseNacional: false,
    showFormAgendamento: false,
    showFormUnicoTributosWeb: false,
    showFormRotinas: false,
    showFormSolicitacaoRPS: false,
    showFormTributos: false,
    showFormFiscalizacao: false,
    showFormDesIf: false,

    loading: false,

    showMessageBoxMensagemApresentacao: false,
    showMessageBoxMensagemAlerta: false,
    showMessageBoxNotaExplicativaCartaCorrecao: false,

    tipoImagemLogomarca: {},
    tipoRepresentanteLegal: {},
    tiposFluxoCancelamentoNfse: {},
    tiposFluxoSubstituicaoNfse: {},

    tiposProcessos: [],
    tiposAtosProcessos: []
  };

  componentDidMount() {
    this.serviceSearch();
  }

  atribuirLoading = estado => {
    this.setState({ loading: estado });
  };

  getParametrosSuccess = async response => {
    const parametros = response.data;
    this.setState({
      parametros
    });

    if (
      parametros.HABILITASOLICITACAOBAIXA &&
      parametros.HABILITASOLICITACAOBAIXA.valor
    ) {
      await this.props.tipoProcessoService
        .loadProcessos('', { size: -1 })
        .then(this.getSolicitacaoBaixaTipoProcessoSuccess)
        .catch(this.getSolicitacaoBaixaTipoProcessoError);
    }

    if (
      parametros.TIPOREPRESENTANTELEGAL &&
      parametros.TIPOREPRESENTANTELEGAL.valor
    ) {
      await this.props.tipoRepresentanteService
        .findTipoRepresentanteById(parametros.TIPOREPRESENTANTELEGAL.valor)
        .then(this.getTipoRepresentanteSuccess)
        .catch(this.getTipoRepresentanteError);
    }

    if (
      parametros.TIPOIMAGEMLOGOMARCA &&
      parametros.TIPOIMAGEMLOGOMARCA.valor
    ) {
      await this.props.tipoImagemService
        .findTipoImagemById(parametros.TIPOIMAGEMLOGOMARCA.valor)
        .then(this.getTipoImagemLogomarcaSuccess)
        .catch(this.getTipoImagemLogomarcaError);
    }
  };

  getFluxosAutorizacaoSuccess = async response => {
    this.setState({ tiposFluxoCancelamentoNfse: response.data });
    this.setState({ tiposFluxoSubstituicaoNfse: response.data });
  };

  getFluxosAutorizacaoError = () => {
    Alert.error({
      title: 'Ocorreu uma falha ao carregar os Parâmetros'
    });
  };

  getTipoImagemLogomarcaSuccess = response => {
    this.setState({ tipoImagemLogomarca: response.data });
  };

  getTipoImagemLogomarcaError = () => {
    this.setState({ loading: false });
    Alert.error({
      title: 'Error ao carregar Tipo Imagem Logomarca'
    });
  };

  getTipoRepresentanteSuccess = response => {
    this.setState({ tipoRepresentanteLegal: response.data });
  };

  getTipoRepresentanteError = () => {
    Alert.error({
      title: 'Erro ao carregar Tipo Representante Legal'
    });
  };

  getSolicitacaoBaixaTipoProcessoSuccess = async response => {
    const { parametros } = this.state;
    const { tipoProcessoAtoService } = this.props;

    const tiposDeProcessos = response.data?.content;

    const tiposProcesso = tiposDeProcessos.filter(
      item => item.id === +parametros.SOLICITACAOBAIXA_TIPO_PROCESSO.valor
    );

    const tipoProcesso = tiposProcesso && tiposProcesso[0];

    this.setState(
      {
        tiposProcessos: tiposDeProcessos
      },
      async () => {
        if (tipoProcesso) {
          await tipoProcessoAtoService
            .loadTiposAtos(tipoProcesso.id)
            .then(result => {
              const atos = result.data;
              const tiposProcessoAto = atos.filter(
                item => item.id === +parametros.SOLICITACAOBAIXA_TIPO_ATO.valor
              );
              const tipoProcessoAto = tiposProcessoAto && tiposProcessoAto[0];
              this.setState({
                tiposAtosProcessos: atos,
                solicitacaoBaixaTipoAto: tipoProcessoAto ?? {}
              });
            })
            .catch(() => {
              Alert.error({
                title: 'Erro ao carregar Tipo Ato Processo'
              });
            });
        }
      }
    );
  };

  getSolicitacaoBaixaTipoProcessoError = () => {
    Alert.error({
      title: 'Erro ao carregar Tipo Processo'
    });
  };

  onChangeSolicitacaoBaixaTipoProcesso(value) {
    const { tiposProcessos } = this.state;
    const { tipoProcessoAtoService } = this.props;

    const tipoProcesso = tiposProcessos.filter(item => item.id === +value)[0];

    tipoProcesso &&
      this.setState(
        prevState => {
          return {
            ...prevState,
            tiposAtosProcessos: [],
            parametros: {
              ...prevState.parametros,
              SOLICITACAOBAIXA_TIPO_ATO: {
                ...prevState.parametros.SOLICITACAOBAIXA_TIPO_ATO,
                parametro: {
                  modulo: Modulos.ISS,
                  parametro: 'SOLICITACAOBAIXA_TIPO_ATO'
                }
              }
            }
          };
        },
        async () => {
          if (tipoProcesso) {
            await tipoProcessoAtoService
              .loadTiposAtos(tipoProcesso.id)
              .then(result => {
                this.setState({
                  tiposAtosProcessos: result.data
                });
              })
              .catch(() => {
                Alert.error({
                  title: 'Erro ao carregar Tipo Ato Processo'
                });
              });
          }
        }
      );
  }
  onChangeSolicitacaoBaixaTipoProcessoAto(value) {
    const { tiposAtosProcessos } = this.state;
    const tipoProcessoAto = tiposAtosProcessos.filter(
      item => item.id === +value
    )[0];

    this.setState(prevState => {
      return {
        ...prevState,
        solicitacaoBaixaTipoAto: tipoProcessoAto ?? {}
      };
    });
  }

  getParametrosError = () => {
    Alert.error({
      title: 'Ocorreu uma falha ao carregar os Parâmetros'
    });
  };

  serviceSearch = () => {
    this.setState({ loading: true });

    this.props.configuracaoParametrosService
      .loadFluxosAutorizacaoNfse()
      .then(this.getFluxosAutorizacaoSuccess)
      .catch(this.getFluxosAutorizacaoError);

    this.props.configuracaoParametrosService
      .loadParametros()
      .then(this.getParametrosSuccess)
      .catch(this.getParametrosError)
      .finally(() => this.setState({ loading: false }));
  };

  onChangeParametros = event => {
    const { name, value, checked, type } = event.target;
    const novoValor = type === 'checkbox' ? (checked ? 'S' : 'N') : value;
    const evaluateParameter = (nameCheckbox, target) => {
      if (nameCheckbox === name && novoValor === 'N') {
        this.setState(state => ({
          parametros: {
            ...state.parametros,
            [target]: {
              ...state.parametros[target],
              valor: ''
            }
          }
        }));
      }
    };

    evaluateParameter(
      'PERMITE_INCENTIVO_0430_DESIF',
      'PERCENTUAL_MAXIMO_INCENTIVO_0430_DESIF'
    );

    evaluateParameter(
      'PERMITE_INCENTIVO_0440_DESIF',
      'PERCENTUAL_MAXIMO_INCENTIVO_0440_DESIF'
    );

    evaluateParameter(
      'PERMITE_MAXVALOR_COMPENSAR_0440_DESIF',
      'MAXVALOR_COMPENSAR_0440_DESIF'
    );
    evaluateParameter(
      'PERMITE_MAXVALOR_COMPENSAR_0440_DESIF',
      'MAXPERCDEVIDO_COMPENSAR_0440_DESIF'
    );
    evaluateParameter(
      'PERMITE_MAXVALOR_COMPENSAR_0440_DESIF',
      'MAXPERCPAGAR_COMPENSAR_0440_DESIF'
    );

    this.setState(state => ({
      parametros: {
        ...state.parametros,
        [`${name}`]: {
          ...state.parametros[`${name}`],
          valor: novoValor
        }
      }
    }));
  };

  onToggleDadosGerais = () => {
    this.setState(state => {
      return {
        showFormDadosGerais: !state.showFormDadosGerais
      };
    });
  };

  onToggleIss = () => {
    this.setState(state => {
      return {
        showFormIss: !state.showFormIss
      };
    });
  };

  onToggleDesIf = () => {
    this.setState(state => {
      return {
        showFormDesIf: !state.showFormDesIf
      };
    });
  };

  onToggleAgendamento = () => {
    this.setState(state => {
      return {
        showFormAgendamento: !state.showFormAgendamento
      };
    });
  };

  onToggleNfse = () => {
    this.setState(state => {
      return {
        showFormNfse: !state.showFormNfse
      };
    });
  };

  onToggleUnicoTributosWeb = () => {
    this.setState(state => {
      return {
        showFormUnicoTributosWeb: !state.showFormUnicoTributosWeb
      };
    });
  };

  onToggleRotinas = () => {
    this.setState(state => {
      return {
        showFormRotinas: !state.showFormRotinas
      };
    });
  };

  onToggleMessageBoxMensagemApresetacao = () => {
    this.setState(state => {
      return {
        showMessageBoxMensagemApresentacao: !state.showMessageBoxMensagemApresentacao
      };
    });
  };

  onToggleMessageBoxMensagemAlerta = () => {
    this.setState(state => {
      return {
        showMessageBoxMensagemAlerta: !state.showMessageBoxMensagemAlerta
      };
    });
  };

  onToggleMessageBoxNotaExplicativaCartaCorrecao = () => {
    this.setState(state => {
      return {
        showMessageBoxNotaExplicativaCartaCorrecao: !state.showMessageBoxNotaExplicativaCartaCorrecao
      };
    });
  };

  onToggleSolicitacaoRPS = () =>
    this.setState(state => ({
      showFormSolicitacaoRPS: !state.showFormSolicitacaoRPS
    }));

  onToggleTributos = () =>
    this.setState(state => ({
      showFormTributos: !state.showFormTributos
    }));

  onToggleConfiguracaoEmail = () =>
    this.setState(state => ({
      showFormConfiguracaoEmail: !state.showFormConfiguracaoEmail
    }));

  onToggleFiscalizacao = () => {
    this.setState(state => ({
      showFormFiscalizacao: !state.showFormFiscalizacao
    }));
  };

  onToggleNfseNacional = () => {
    this.setState(state => ({
      showFormNfseNacional: !state.showFormNfseNacional
    }));
  };

  onCancelMensagemApresentacao = () => {
    this.setState(state => {
      return {
        showMessageBoxMensagemApresentacao: !state.showMessageBoxMensagemApresentacao
      };
    });
  };

  onCancelMensagemAlerta = () => {
    this.setState(state => {
      return {
        showMessageBoxMensagemAlerta: !state.showMessageBoxMensagemAlerta
      };
    });
  };

  onCancelNotaExplicativaCartaCorrecao = () => {
    this.setState(state => {
      return {
        showMessageBoxNotaExplicativaCartaCorrecao: !state.showMessageBoxNotaExplicativaCartaCorrecao
      };
    });
  };

  onSaveMessageBoxMensagemApresentacao = () => {
    this.setState(state => {
      return {
        showMessageBoxMensagemApresentacao: !state.showMessageBoxMensagemApresentacao
      };
    });
    this.props.showNotification({
      level: 'success',
      message: 'Mensagem de apresentação salvo.'
    });
  };

  onSaveMessageBoxMensagemAlerta = () => {
    this.setState(state => {
      return {
        showMessageBoxMensagemAlerta: !state.showMessageBoxMensagemAlerta
      };
    });
    this.props.showNotification({
      level: 'success',
      message: 'Mensagem de alerta salvo.'
    });
  };

  onSaveMessageBoxNotaExplicativaCartaCorrecao = () => {
    this.setState(state => {
      return {
        showMessageBoxNotaExplicativaCartaCorrecao: !state.showMessageBoxNotaExplicativaCartaCorrecao
      };
    });
    this.props.showNotification({
      level: 'success',
      message: 'Nota explicativa carta correção salvo.'
    });
  };

  onSaveSuccess = () => {
    Alert.success({
      title: 'Salvo com sucesso!'
    });
    this.setState({
      loading: false,
      showFormIss: false,
      showFormAgendamento: false,
      showFormRotinas: false,
      showFormNfse: false,
      showFormUnicoTributosWeb: false,
      showMessageBoxMensagemAlerta: false,
      showMessageBoxMensagemApresentacao: false,
      showMessageBoxNotaExplicativaCartaCorrecao: false
    });
  };

  onSaveError = () => {
    this.setState({ loading: false });
    Alert.error({
      title: 'Não foi possível salvar Parametros'
    });
  };

  validateIfNull = (parameter, insert, fieldName) => {
    if (this.state.parametros[parameter].valor === 'S') {
      if (this.state.parametros[insert]?.valor === '') {
        return Alert.error({
          title: `${fieldName} é obrigatório`
        });
      }
    }
  };

  onSave = () => {
    this.validateIfNull(
      'PERMITE_INCENTIVO_0430_DESIF',
      'PERCENTUAL_MAXIMO_INCENTIVO_0430_DESIF',
      'Percentual máximo do incentivo por subtítulo'
    );

    this.validateIfNull(
      'PERMITE_INCENTIVO_0440_DESIF',
      'PERCENTUAL_MAXIMO_INCENTIVO_0440_DESIF',
      'Percentual máximo do incentivo por tipo de consolidação'
    );

    this.validateIfNull(
      'PERMITE_MAXVALOR_COMPENSAR_0440_DESIF',
      'MAXVALOR_COMPENSAR_0440_DESIF',
      'Valor máximo a compensar por indébito fiscal'
    );

    this.validateIfNull(
      'PERMITE_MAXVALOR_COMPENSAR_0440_DESIF',
      'MAXPERCPAGAR_COMPENSAR_0440_DESIF',
      'Percentual máximo do ISSQN a pagar'
    );

    this.validateIfNull(
      'PERMITE_MAXVALOR_COMPENSAR_0440_DESIF',
      'MAXPERCDEVIDO_COMPENSAR_0440_DESIF',
      'Percentual máximo do ISSQN'
    );

    Alert.question({
      title: 'Informe o motivo da alteração',
      input: 'textarea',
      inputPlaceholder: '',
      inputValidator: value =>
        value.length === 0
          ? 'Campo obrigatório!'
          : !(value.length < 4000)
          ? 'Excedeu o limite de 4000 caracteres'
          : null,
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancelar'
    }).then(result => {
      if (result.value) {
        const { parametros } = this.state;
        const motivo = result.value;
        const alteracaoParametro = {
          parametros,
          motivo
        };
        this.setState({ loading: true });
        this.props.configuracaoParametrosService
          .update(alteracaoParametro)
          .then(() => {
            this.props.schedulerService
              .registerSchedulerDMSVencida()
              .then(this.onSaveSuccess)
              .catch(this.onSaveError);
            this.props.schedulerService
              .registerSchedulerEntregaDmsAutomatico()
              .then(this.onSaveSuccess)
              .catch(this.onSaveError);
          })
          .catch(this.onSaveError);
      }
    });
  };

  onSelectLogomarca = (name, value) => {
    this.setState(state => {
      return {
        parametros: {
          ...state.parametros,
          [name]: {
            ...state.parametros[name],
            valor: value.id
          }
        },
        tipoImagemLogomarca: value
      };
    });
  };

  onSelectTipoRepresentanteLegal = (name, value) => {
    this.setState(state => {
      return {
        parametros: {
          ...state.parametros,
          [name]: {
            ...state.parametros[name],
            valor: value.id
          }
        },
        tipoRepresentanteLegal: value
      };
    });
  };
  render() {
    const {
      parametros,
      tiposFluxoCancelamentoNfse,
      tiposFluxoSubstituicaoNfse,
      tipoRepresentanteLegal,
      tipoImagemLogomarca,
      showFormDadosGerais,
      showFormIss,
      showFormAgendamento,
      showFormNfse,
      showFormNfseNacional,
      showFormUnicoTributosWeb,
      showFormRotinas,
      showMessageBoxMensagemApresentacao,
      showMessageBoxMensagemAlerta,
      showMessageBoxNotaExplicativaCartaCorrecao,
      showFormSolicitacaoRPS,
      showFormTributos,
      showFormConfiguracaoEmail,
      showFormFiscalizacao,
      showFormDesIf,
      loading,
      tiposProcessos,
      tiposAtosProcessos
    } = this.state;
    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a href="#localhost:3000" target="_blank" rel="noopener noreferrer">
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />
        <DadosGeraisForm
          dadosGerais={parametros}
          tipoImagemLogomarca={tipoImagemLogomarca}
          onChangeParametros={this.onChangeParametros}
          onToggleDadosGerais={this.onToggleDadosGerais}
          showFormDadosGerais={showFormDadosGerais}
          onToggleMessageBoxMensagemApresetacao={
            this.onToggleMessageBoxMensagemApresetacao
          }
          onToggleMessageBoxMensagemAlerta={
            this.onToggleMessageBoxMensagemAlerta
          }
          onToggleMessageBoxNotaExplicativaCartaCorrecao={
            this.onToggleMessageBoxNotaExplicativaCartaCorrecao
          }
          showMessageBoxMensagemApresentacao={
            showMessageBoxMensagemApresentacao
          }
          showMessageBoxMensagemAlerta={showMessageBoxMensagemAlerta}
          onCancelMensagemApresentacao={this.onCancelMensagemApresentacao}
          onCancelMensagemAlerta={this.onCancelMensagemAlerta}
          onCancelNotaExplicativaCartaCorrecao={
            this.onCancelNotaExplicativaCartaCorrecao
          }
          showMessageBoxNotaExplicativaCartaCorrecao={
            showMessageBoxNotaExplicativaCartaCorrecao
          }
          onSaveMessageBoxMensagemApresentacao={
            this.onSaveMessageBoxMensagemApresentacao
          }
          onSaveMessageBoxMensagemAlerta={this.onSaveMessageBoxMensagemAlerta}
          onSaveMessageBoxNotaExplicativaCartaCorrecao={
            this.onSaveMessageBoxNotaExplicativaCartaCorrecao
          }
          onSelectLogomarca={this.onSelectLogomarca}
          loadLogomarca={this.props.tipoImagemService.loadTiposImagens}
        />
        <TributosForm
          dadosTributos={parametros}
          onChangeParametros={this.onChangeParametros}
          onToggleTributos={this.onToggleTributos}
          showFormTributos={showFormTributos}
        />
        <IssForm
          dadosIss={parametros}
          onChangeParametros={this.onChangeParametros}
          onToggleIss={this.onToggleIss}
          showFormIss={showFormIss}
        />
        <DesIfForm
          dadosDesIf={parametros}
          onChangeParametros={this.onChangeParametros}
          onToggleDesIf={this.onToggleDesIf}
          showFormDesIf={showFormDesIf}
        />
        <AgendamentosForm
          dadosAgendamento={parametros}
          tipoRepresentanteLegal={tipoRepresentanteLegal}
          onChangeParametros={this.onChangeParametros}
          onToggleAgendamento={this.onToggleAgendamento}
          showFormAgendamento={showFormAgendamento}
          loadTipoRepresentante={
            this.props.tipoRepresentanteService.loadTipoRepresentante
          }
          onSelectTipoRepresentanteLegal={this.onSelectTipoRepresentanteLegal}
        />
        <NfseForm
          dadosNfse={parametros}
          tiposFluxoCancelamentoNfse={tiposFluxoCancelamentoNfse}
          tiposFluxoSubstituicaoNfse={tiposFluxoSubstituicaoNfse}
          onChangeParametros={this.onChangeParametros}
          onToggleNfse={this.onToggleNfse}
          showFormNfse={showFormNfse}
        />
        <NfseNacionalForm
          dados={parametros}
          onChangeParametros={this.onChangeParametros}
          onToggle={this.onToggleNfseNacional}
          showForm={showFormNfseNacional}
        />
        <UnicoTributosWebForm
          dadosUnicoTributosWeb={parametros}
          onToggleUnicoTributosWeb={this.onToggleUnicoTributosWeb}
          showFormUnicoTributosWeb={showFormUnicoTributosWeb}
          onChangeParametros={this.onChangeParametros}
        />
        <RotinasForm
          dadosRotinas={parametros}
          showFormRotinas={showFormRotinas}
          onToggleRotinas={this.onToggleRotinas}
          onChangeParametros={this.onChangeParametros}
        />
        <SolicitacaoRPSForm
          dados={parametros}
          showForm={showFormSolicitacaoRPS}
          onToggle={this.onToggleSolicitacaoRPS}
          onChange={this.onChangeParametros}
        />
        <ConfiguracaoEmailForm
          parametrosConfiguracaoEmail={parametros}
          onChangeParametros={this.onChangeParametros}
          onToggleConfiguracaoEmail={this.onToggleConfiguracaoEmail}
          showFormConfiguracaoEmail={showFormConfiguracaoEmail}
          servicoEnviarEmail={this.props.configuracaoParametrosService}
          loading={this.atribuirLoading}
        />
        <FiscalizacaoForm
          dados={parametros}
          onChangeParametros={this.onChangeParametros}
          onToggle={this.onToggleFiscalizacao}
          showForm={showFormFiscalizacao}
          tiposProcesso={tiposProcessos}
          tiposAtosProcessos={tiposAtosProcessos}
          onChangeSolicitacaoBaixaTipoProcesso={value =>
            this.onChangeSolicitacaoBaixaTipoProcesso(value)
          }
          onChangeSolicitacaoBaixaTipoProcessoAto={value =>
            this.onChangeSolicitacaoBaixaTipoProcessoAto(value)
          }
        />

        <div className="btn-save">
          <FAB
            data-test-id="buttonSave"
            icon="check"
            typeButton="module-color"
            iconColor="white"
            title="Salvar alterações"
            onClick={() => this.onSave(parametros)}
          />
        </div>
      </Container>
    );
  }
}

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const ComponentWithService = withService({
  configuracaoParametrosService: ConfiguracaoParametrosService,
  tipoRepresentanteService: TipoRepresentanteService,
  tipoImagemService: TipoImagemService,
  schedulerService: SchedulerService,
  tipoProcessoService: TipoProcessoService,
  tipoProcessoAtoService: TipoProcessoAtoService
})(ParametrosPage);

export default connect(null, mapDispatchToProps)(ComponentWithService);
