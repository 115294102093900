import {
  Alert,
  Container,
  Loading,
  NotificationActions
} from '@elotech/components';
import download from 'downloadjs';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert2';

import { CancelamentoNotaFiscalService, withService } from '../../../service';
import NotaFiscalService from '../../../service/NotaFiscalService';
import AnaliseCancelamento from './AnaliseCancelamento';

export class AnaliseCancelamentoFormPage extends Component {
  static propTypes = {
    cancelamentoNotaFiscalService: PropTypes.object.isRequired
  };

  state = {
    loading: false,
    solicitacaoCancelamento: {},
    abrirTelaDetalheHistorico: false,
    detalheHistorico: {},
    motivoCancelamentoNota: {
      id: '',
      usuario: {},
      motivo: ''
    },
    error: {
      motivo: false
    }
  };

  validators = {
    motivo: value => value
  };

  componentDidMount() {
    const { id } = this.props.match.params;

    if (id) {
      this.setState({ loading: true });
      this.props.cancelamentoNotaFiscalService
        .loadSolicitacaoCancelamentoById(id)
        .then(this.loadSolicitacaoCancelamentoSuccess)
        .catch(this.loadSolicitacaoCancelamentoError);
    }
  }

  loadSolicitacaoCancelamentoSuccess = response => {
    this.setState(state => {
      const { motivoCancelamentoNota } = state;
      return {
        solicitacaoCancelamento: response.data,
        motivoCancelamentoNota: {
          ...motivoCancelamentoNota,
          id: response.data.id,
          usuario: this.props.usuario
        },
        loading: false
      };
    });
  };
  '';

  loadSolicitacaoCancelamentoError = error => {
    this.setState({ loading: false });
    Alert.error(
      { title: 'Não foi possível carregar a solicitação de cancelamento.' },
      error
    );
  };

  visualizarNFSe = async () => {
    const { solicitacaoCancelamento } = this.state;

    this.setState({ loading: true });
    await NotaFiscalService.viewPdfNfseCancelamento(
      solicitacaoCancelamento.idNotaFiscal
    )
      .then(response => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(file);

        window.open(fileUrl);
      })
      .catch(e => {
        Alert.error({ title: 'Houve um erro ao tentar visualizar a NFSe.' }, e);
      })
      .finally(() => this.setState({ loading: false }));
  };

  openQuickViewDetail = data => {
    this.setState({
      abrirTelaDetalheHistorico: true,
      detalheHistorico: data
    });
  };

  closeQuickView = () => {
    this.setState({
      abrirTelaDetalheHistorico: false,
      detalheHistorico: {}
    });
  };

  hasErrors = () => {
    const { motivoCancelamentoNota } = this.state;

    const errors = Object.keys(motivoCancelamentoNota).filter(field => {
      return (
        this.validators[field] &&
        !this.validators[field](motivoCancelamentoNota[field])
      );
    });

    const objErro = errors.reduce((total, current) => {
      total[current] = true;
      return total;
    }, {});

    this.setState({ error: objErro });

    return errors.length > 0;
  };

  generateUrlAnexo = arquivo => {
    this.setState({ loading: true });
    this.props.cancelamentoNotaFiscalService
      .geraUrlAnexo(arquivo)
      .then(response => {
        download(response.data);
      })
      .catch(error => {
        Alert.error({ title: 'Não foi possível imprimir a evidência.' }, error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  aprovarSolicitacaoCancelamento = () => {
    if (this.hasErrors()) {
      this.props.showNotification({
        level: 'error',
        message: 'Campos Obrigatórios'
      });
    } else {
      swal({
        title: 'Deseja aprovar a solicitação de cancelamento?',
        type: 'question',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
      }).then(result => {
        if (result.value) {
          const { motivoCancelamentoNota } = this.state;
          this.props.cancelamentoNotaFiscalService
            .aprovarSolicitacaoCancelamento(motivoCancelamentoNota)
            .then(this.solicitacaoAprovadaSuccess)
            .catch(this.solicitacaoAprovadaError);
        }
      });
    }
  };

  rejeitarSolicitacaoCancelamento = () => {
    if (this.hasErrors()) {
      this.props.showNotification({
        level: 'error',
        message: 'Campos Obrigatórios'
      });
    } else {
      swal({
        title: 'Deseja rejeitar a solicitação de cancelamento?',
        type: 'question',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
      }).then(result => {
        if (result.value) {
          const { motivoCancelamentoNota } = this.state;
          this.props.cancelamentoNotaFiscalService
            .rejeitarSolicitacaoCancelamento(motivoCancelamentoNota)
            .then(this.solicitacaoRejeitadaSuccess)
            .catch(this.solicitacaoRejeitadaError);
        }
      });
    }
  };

  solicitacaoAprovadaSuccess = response => {
    this.props.showNotification({
      level: 'success',
      message: 'Solicitação de cancelamento aprovada com sucesso.'
    });

    this.alertaDesejaEnviarEmail(response);
    this.setState({ motivoCancelamentoNota: response.data });
  };

  solicitacaoAprovadaError = error => {
    Alert.error(
      { title: 'Não foi possível aprovar a solicitação de cancelamento.' },
      error
    );
  };

  solicitacaoRejeitadaSuccess = response => {
    this.props.showNotification({
      level: 'success',
      message: 'Solicitação de cancelamento rejeitada com sucesso.'
    });

    this.alertaDesejaEnviarEmail(response);
  };

  solicitacaoRejeitadaError = error => {
    Alert.error(
      { title: 'Não foi possível rejeitar a solicitação de cancelamento.' },
      error
    );
  };

  enviarEmailSuccess = () => {
    this.setState({ loading: false });
    this.props.showNotification({
      level: 'success',
      message: 'E-mail enviado com sucesso.'
    });

    this.props.history.replace('/acessos-e-permissoes/cancelamento-nfse');
  };

  enviarEmailError = error => {
    this.setState({ loading: false });
    Alert.error(
      { title: 'Erro ao enviar o email para o contribuinte.' },
      error
    );

    this.props.history.replace('/acessos-e-permissoes/cancelamento-nfse');
  };

  alertaDesejaEnviarEmail = response => {
    Alert.question({
      title:
        'Deseja enviar email de notificação de cancelamento de NFSe para o contribuinte?'
    }).then(result => {
      if (result.value) {
        this.setState({ loading: true });
        this.props.cancelamentoNotaFiscalService
          .enviarEmail(response.data.id)
          .then(this.enviarEmailSuccess)
          .catch(this.enviarEmailError);
      }
    }, this.props.history.replace('/acessos-e-permissoes/cancelamento-nfse'));
  };

  onChangeInputValue = event => {
    const { name, value } = event.target;

    this.setState(state => {
      const { motivoCancelamentoNota, error } = state;
      return {
        motivoCancelamentoNota: { ...motivoCancelamentoNota, [name]: value },
        error: { ...error, [name]: !this.validators[name](value) }
      };
    });
  };

  render() {
    const {
      solicitacaoCancelamento,
      abrirTelaDetalheHistorico,
      detalheHistorico,
      loading,
      motivoCancelamentoNota,
      error
    } = this.state;
    return (
      <Container title="Cancelamento de NFSe" icon="file-excel">
        <Loading loading={loading} />

        <AnaliseCancelamento
          solicitacaoCancelamento={solicitacaoCancelamento}
          motivoCancelamentoNota={motivoCancelamentoNota}
          abrirTelaDetalheHistorico={abrirTelaDetalheHistorico}
          error={error}
          detalheHistorico={detalheHistorico}
          aprovar={this.aprovarSolicitacaoCancelamento}
          rejeitar={this.rejeitarSolicitacaoCancelamento}
          visualizarNotaFiscal={this.visualizarNFSe}
          visualizarDetalheHistorico={data => this.openQuickViewDetail(data)}
          fecharTelaDetalheHistorico={this.closeQuickView}
          onChangeInputValue={this.onChangeInputValue}
          generateUrlAnexo={arquivo => this.generateUrlAnexo(arquivo)}
        />
      </Container>
    );
  }
}

const ComponentWithService = withService({
  cancelamentoNotaFiscalService: CancelamentoNotaFiscalService
})(AnaliseCancelamentoFormPage);

const mapStateToProps = state => ({
  usuario: state.user.profile
});

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithService);
