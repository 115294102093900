import {
  ActionButton,
  Alert,
  Button,
  Col,
  DataPage,
  DisplayDataGrid,
  DisplayDataItem,
  FormikTextArea,
  Loading,
  Row,
  SectionTitle,
  Yup
} from '@elotech/components';
import download from 'downloadjs';
import { History } from 'history';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router';

import { CancelamentoGuiaService } from '../../../service';
import { CancelamentoGuia } from '../../../types/CancelamentoGuia';
import { CancelamentoGuiaDocumentoAnexo } from '../../../types/CancelamentoGuiaDocumentoAnexo';

type Props = {
  match: match<{ id: string }>;
  history: Pick<History, 'replace'>;
  usuario: any;
};

const CancelamentoGuiaDataPage: React.FC<Props> = ({
  match,
  usuario,
  history
}) => {
  const [loading, setLoading] = useState(false);
  const onClickOnRejeitar = async (values: CancelamentoGuia) => {
    const answer = await dialog();
    if (answer) {
      setLoading(true);
      CancelamentoGuiaService.rejeitarSolicitacaoCancelamento({
        ...values,
        usuario
      })
        .then(async _ => {
          setLoading(false);
          await Alert.success({ title: 'Rejeitado com sucesso' });
        })
        .finally(() =>
          history.replace('/acessos-e-permissoes/cancelamento-guia/')
        );
    }
  };

  const generateUrlAnexo = (documento: CancelamentoGuiaDocumentoAnexo) => {
    setLoading(true);
    CancelamentoGuiaService.geraUrlAnexo(documento)
      .then(response => {
        download(response.data);
      })
      .catch(error => {
        Alert.error({ title: 'Não foi possível imprimir a evidência.' }, error);
      })
      .finally(() => setLoading(false));
  };

  const onClickOnAprovar = async (values: CancelamentoGuia) => {
    const answer = await dialog();
    if (answer) {
      setLoading(true);
      CancelamentoGuiaService.aprovarSolicitacaoCancelamento({
        ...values,
        usuario
      })
        .then(async _ => {
          setLoading(false);
          await Alert.success({ title: 'Aprovado com sucesso' });
        })
        .catch(error => {
          setLoading(false);
          Alert.error(
            { title: 'Não foi possível aprovar o cancelamento da guia.' },
            error
          );
        })
        .finally(() =>
          history.replace('/acessos-e-permissoes/cancelamento-guia/')
        );
    }
  };

  const dialog = async (): Promise<boolean> =>
    await Alert.question({
      title: 'Deseja realmente realizar esta ação ?'
    }).then((result: any) => result.value);

  const validationSchema = Yup.object().shape({
    motivoAprovacaoRejeicao: Yup.string().required()
  });

  return (
    <DataPage<CancelamentoGuia>
      icon="ban"
      match={match}
      name="cancelamento-guia"
      title="Cancelamento Guia"
      validationSchema={validationSchema}
      load={CancelamentoGuiaService.loadSolicitacaoCancelamentoById}
      render={props => {
        return (
          <>
            <Loading loading={loading}></Loading>
            <DisplayDataGrid column={true}>
              <Row>
                <DisplayDataItem md={6} title="Numero Guia">
                  {props.values.numeroGuia}
                </DisplayDataItem>
                <DisplayDataItem md={6} title="Status Cancelamento Guia">
                  {props.values.status}
                </DisplayDataItem>
              </Row>
            </DisplayDataGrid>

            {props.values.documentosAnexos &&
              props.values.documentosAnexos.length > 0 && (
                <>
                  <SectionTitle>Anexos</SectionTitle>
                  <div className="panel table table-responsive">
                    <div className="panel-body">
                      <div className="panel-table-outer">
                        <table className="panel-table striped fancy">
                          <thead>
                            <tr>
                              {props.values.documentosAnexos && (
                                <th>Evidência</th>
                              )}
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {props.values.documentosAnexos.map(documento => (
                              <tr key={documento.id}>
                                <td>
                                  <th>{documento.nomeArquivo}</th>
                                </td>
                                <td>
                                  <div className="btn-actions">
                                    <div className="btn-actions-inner">
                                      <ActionButton
                                        data-test-id="buttonDowload"
                                        key="download-button"
                                        icon="download"
                                        label="Baixar anexo"
                                        onClick={() =>
                                          generateUrlAnexo(documento)
                                        }
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )}

            <Row>
              <FormikTextArea
                label="Motivo Cancelamento"
                name="motivo"
                key="motivo"
                size={12}
                disabled={true}
              />
            </Row>
            <Row>
              <FormikTextArea
                label="Motivo Aprovação/Rejeição"
                name="motivoAprovacaoRejeicao"
                key="motivoAprovacaoRejeicao"
                size={12}
              />
            </Row>
            <br />
            <Row>
              <Col className="right">
                <Button
                  disabled={!props.isValid}
                  onClick={e => onClickOnAprovar(props.values)}
                  className={'inline'}
                  color="positive"
                >
                  Aprovar
                </Button>
                <Button
                  disabled={!props.isValid}
                  onClick={e => onClickOnRejeitar(props.values)}
                  className={'inline'}
                  color="negative"
                >
                  Rejeitar
                </Button>
              </Col>
            </Row>
          </>
        );
      }}
    />
  );
};

const connectedComponent = connect(
  (state: any) => ({ usuario: state.user.profile }),
  undefined
)(CancelamentoGuiaDataPage);

export { CancelamentoGuiaDataPage, connectedComponent as default };
