import {
  ActionButton,
  Col,
  ErrorText,
  FormattedDate,
  Row,
  SectionTitle
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

import DetailQuickView from './quickview/DetailQuickView';

const errorMessages = {
  motivo: 'Este campo é obrigatório!'
};

const STATUS_AGUARDANDO_APROVACAO = 'Aguardando Aprovação';
const STATUS_APROVADO = 'Aprovado';

const AnaliseCancelamentoDMS = ({
  solicitacaoCancelamento,
  error,
  aprovar,
  rejeitar,
  onChangeInputValue,
  visualizarDetalheHistorico,
  fecharTelaDetalheHistorico,
  detalheHistorico,
  abrirTelaDetalheHistorico,
  generateUrlAnexo
}) => {
  return (
    <>
      <SectionTitle marginTop="0">Analisar DMS</SectionTitle>
      <Row>
        <Col md={6}>
          <Row style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Col md={11}>
              <div className="form-group">
                <label htmlFor="numDMS">Nº DMS</label>
                <input
                  disabled
                  type="number"
                  name="numeroDms"
                  defaultValue={solicitacaoCancelamento.numeroDms}
                />
              </div>
            </Col>
            <Col md={1} style={{ paddingLeft: '0px' }}>
              <div className="form-group" style={{ paddingTop: '10px' }}>
                <ActionButton
                  data-test-id="buttonView"
                  key="eye-button"
                  icon="eye"
                  label="Visualizar Detalhes DMS"
                  onClick={() =>
                    visualizarDetalheHistorico(solicitacaoCancelamento)
                  }
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <label htmlFor="statusCancelamentoDMS">
              Status Cancelamento DMS
            </label>
            <input
              disabled
              type="text"
              name="statusCancelamentoDMS"
              defaultValue={solicitacaoCancelamento.status}
            />
          </div>
        </Col>
      </Row>
      {solicitacaoCancelamento.documentosAnexos &&
        solicitacaoCancelamento.documentosAnexos.length > 0 && (
          <>
            <SectionTitle>Anexos</SectionTitle>
            <div className="panel table table-responsive">
              <div className="panel-body">
                <div className="panel-table-outer">
                  <table className="panel-table striped fancy">
                    <thead>
                      <tr>
                        {solicitacaoCancelamento.documentosAnexos && (
                          <th>Evidência</th>
                        )}
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {solicitacaoCancelamento.documentosAnexos.map(
                        documento => (
                          <tr key={documento.id}>
                            <td>
                              <th>{documento.nomeArquivo}</th>
                            </td>
                            <td>
                              <div className="btn-actions">
                                <div className="btn-actions-inner">
                                  <ActionButton
                                    data-test-id="buttonDowload"
                                    key="download-button"
                                    icon="download"
                                    label="Baixar anexo"
                                    onClick={() => generateUrlAnexo(documento)}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      <Row>
        <Col md={12}>
          <div className="form-group">
            <SectionTitle>Motivo</SectionTitle>
            <textarea disabled value={solicitacaoCancelamento.motivo} />
          </div>
        </Col>
      </Row>
      {!!solicitacaoCancelamento.usuarioFiscalNome &&
        !!solicitacaoCancelamento.dataAprovacaoRejeicao && (
          <Row>
            <Col md={3}>
              <div className="form-group">
                <SectionTitle marginTop="0">
                  Fiscal de{' '}
                  {solicitacaoCancelamento.status === STATUS_APROVADO
                    ? 'Aprovação'
                    : 'Rejeição'}
                </SectionTitle>

                {solicitacaoCancelamento?.usuarioFiscalNome}
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <SectionTitle marginTop="0">
                  Data de{' '}
                  {solicitacaoCancelamento.status === STATUS_APROVADO
                    ? 'Aprovação'
                    : 'Rejeição'}
                </SectionTitle>

                {solicitacaoCancelamento?.dataAprovacaoRejeicao && (
                  <FormattedDate
                    value={solicitacaoCancelamento.dataAprovacaoRejeicao}
                    timeZone={'UTC'}
                  />
                )}
              </div>
            </Col>
          </Row>
        )}
      <Row className="mb-xs">
        <Col md={12}>
          <div className="form-group">
            <SectionTitle>Motivo Aprovação ou Rejeição Fiscal</SectionTitle>
            <textarea
              className={error.motivoAprovacaoRejeicao ? 'error' : ''}
              disabled={
                STATUS_AGUARDANDO_APROVACAO !== solicitacaoCancelamento.status
              }
              onChange={onChangeInputValue}
              name="motivoAprovacaoRejeicao"
              value={solicitacaoCancelamento.motivoAprovacaoRejeicao}
            />
            {error.motivoAprovacaoRejeicao && (
              <ErrorText>{errorMessages.motivo}</ErrorText>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="right">
          <button
            data-test-id="approveButton"
            className="btn inline positive"
            onClick={aprovar}
            disabled={
              STATUS_AGUARDANDO_APROVACAO !== solicitacaoCancelamento.status
            }
          >
            Aprovar
          </button>
          <button
            data-test-id="rejectButton"
            className="btn inline outlined negative"
            onClick={rejeitar}
            disabled={
              STATUS_AGUARDANDO_APROVACAO !== solicitacaoCancelamento.status
            }
          >
            Rejeitar
          </button>
        </Col>
      </Row>
      <DetailQuickView
        data={detalheHistorico}
        onClose={fecharTelaDetalheHistorico}
        opened={abrirTelaDetalheHistorico}
      />
    </>
  );
};

AnaliseCancelamentoDMS.propTypes = {
  aprovar: PropTypes.func.isRequired,
  rejeitar: PropTypes.func.isRequired,
  onChangeInputValue: PropTypes.func.isRequired,
  solicitacaoCancelamento: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired
};

export default AnaliseCancelamentoDMS;
