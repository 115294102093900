import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id' };

const search = params => (params ? `search=${params}` : '');

export const loadCancelamentosDms = (searchParams, page) =>
  axios.get(`${CONTEXT_PATH}/cancelamento-dms?${search(searchParams)}`, {
    params: { ...defaultSort, ...page }
  });

export const loadSolicitacaoCancelamentoById = id =>
  axios.get(`${CONTEXT_PATH}/cancelamento-dms/${id}`);

export const rejeitarSolicitacaoCancelamento = solicitacao =>
  axios.put(`${CONTEXT_PATH}/cancelamento-dms/rejeitar`, solicitacao);

export const aprovarSolicitacaoCancelamento = solicitacao =>
  axios.put(`${CONTEXT_PATH}/cancelamento-dms/aprovar`, solicitacao);

export const geraUrlAnexo = documento =>
  axios.get(
    `${CONTEXT_PATH}/cancelamento-dms/download-anexo/${documento.idDocumentoCancelamentoDms}`,
    documento
  );

export const enviarEmail = idCancelamento =>
  axios.post(
    `${CONTEXT_PATH}/cancelamento-dms/enviarEmailCancelamentoDms/${idCancelamento}`
  );
